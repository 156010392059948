import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import star1 from "../../assets/star-1.jpg";
import star2 from "../../assets/star-2.jpg";
import star3 from "../../assets/star-3.jpg";
import star4 from "../../assets/star-4.jpg";
import star5 from "../../assets/star-5.jpg";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";
import PopoverVerified from "../PopoverVerified";
import ReportComponent from "../ReportComponent";
import LikeComponent from "../likeComponent";
import ShareComponent from "../shareComponnet";

import level0 from "../../assets/level0.jpg";
import level1 from "../../assets/level1.jpg";
import level2 from "../../assets/level2.jpg";
import level3 from "../../assets/level3.jpg";
import level4 from "../../assets/level4.jpg";
// import star0 from "../../assets/star-1.png";

function ServiceReview({ data }) {
  let srcvalue = "star" + data.rating.toString();
  let srcimg = star1;
  let imgUser = level0;
  const axios = useAxios();
  const [hashtransaction, setHashtransaction] = useState("");
  const [responseData, setResponseData] = useState([]);
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  switch (srcvalue) {
    case "star1":
      srcimg = star1;
      break;
    case "star2":
      srcimg = star2;
      break;
    case "star3":
      srcimg = star3;
      break;
    case "star4":
      srcimg = star4;
      break;
    case "star5":
      srcimg = star5;
      break;

    default:
  }

  switch (data.level_account) {
    case 0:
      imgUser = level0;
      break;
    case 1:
      imgUser = level1;

      break;
    case 2:
      imgUser = level2;
      break;

    case 3:
      imgUser = level3;
      break;

    case 4:
      imgUser = level4;
      break;

    default:
  }

  const [productreview, setProductReview] = useState([]);

  useEffect(() => {
    let fetchReviews = async () => {
      axios
        .get(`/data/findproductreview/${data.order_id}`)
        .then((response) => {
          setProductReview(response.data);
        })
        .catch((error) => {
          //.error(error);
        });
    };
    if (data.order_id) {
      fetchReviews();
    }
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      axios
        .get(`/data/transaction-get/${data.job_id}`)
        .then((response) => {
          setHashtransaction(response.data.hash_transaction);
        })
        .catch((error) => {
          //.error(error);
        });

      const responses = await axios.get("/responses/" + data.id);

      //.log(responses);

      if (responses) setResponseData(responses.data);
      else setResponseData([]);
    };

    fetchReviews();
  }, [data.id]);

  return (
    <>
      <Helmet>
        {/* Common meta tags */}
        <meta charSet="utf-8" />
        <title>{data.title}</title>
        <meta name="description" content={data.content} />

        {/* Open Graph meta tags for social media */}
        <meta property="og:title" content={data.title} />
        <meta property="og:description" content={data.content} />
        <meta
          property="og:url"
          content={"https://api.veritatrust.com/merchantreview/" + data.id}
        />
        <meta property="og:image" content={star5} />
        <meta property="og:type" content="website" />
        {/* Add more OG tags as needed */}
      </Helmet>
      <div
        className="bg-white p-3 mb-2 rounded-5 shadow aos animated aos-init aos-animate"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="profil d-flex pb-2">
          <img
            src={data.profile_url ? data.profile_url : imgUser}
            width="50"
            height="50"
          />
          <div className="info ms-3">
            <Link to={"/" + t("lang") + "/users/" + data.userid}>
              <p className="name lead mb-0">
                {data.nickName
                  ? data.nickName
                  : data.last_name
                  ? data.first_name + " " + data.last_name[0] + "."
                  : "Anonymous"}
              </p>
            </Link>
            <p className="analyse text-muted mb-0">
              {data.Nbre} {t("ReviewMerchantPageReviews")} •{" "}
              <i className="flaticon-location"></i> {data.location}
            </p>
          </div>
          <div className="date text-primary ms-auto text-end small">
            {moment(data.createdAt).format("MMM DD, YYYY")}
          </div>
        </div>
        <div className="notation d-flex justify-content-between align-items-center py-2 mb-2">
          <div className="note small me-auto">
            <div className="form__star d-flex justify-content-start">
              <div>
                {" "}
                <img
                  alt="Noté 5 sur 5 étoiles"
                  src={srcimg}
                  width="120"
                  height="70"
                />{" "}
              </div>
            </div>
          </div>

          <PopoverVerified hash_transaction={hashtransaction} />
        </div>
        <div className="content mb-2">
          <h3 className="text-primary">
            {" "}
            <Link to={"/" + t("lang") + "/merchantreview/" + data.id}>
              {data.title}{" "}
            </Link>{" "}
          </h3>
          <p className="text-break">{data.content}</p>
          <p className="text-muted mb-1">
            {t("ReviewMerchantPageExperienceDate")}:{" "}
            {data.experienceDate
              ? moment(data.experienceDate).format("MMM DD, YYYY")
              : moment(data.createdAt).format("MMM DD, YYYY")}
          </p>
        </div>

        {data.order_id && productreview.length > 0 && (
          <div className="related_product">
            <h4 className="h5">Related product review</h4>
            <div className="d-flex">
              <div className="photo">
                <img
                  src={productreview[0].image_video?.split(",")[0]}
                  alt={productreview[0].title}
                />
              </div>
              <div className="related_content">
                <p className="text-primary mb-0">{productreview[0].title}</p>
                <p className="mb-0">
                  (
                  {data.nickName
                    ? data.first_name + " " + data.last_name[0] + "."
                    : data.nickName}
                  ) has rated this product 5/5
                </p>
                <Link
                  className="btn btn-secondary btn-sm mt-2"
                  to={"/" + t("lang") + "/productreview/" + productreview[0].id}
                >
                  Views details
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className="share d-flex justify-content-around">
          <LikeComponent
            reviewdata={data}
            reviewtype={"merchant_review"}
          ></LikeComponent>
          <ShareComponent
            Url={"https://api.veritatrust.com/merchantreview/" + data.id}
          ></ShareComponent>

          <ReportComponent
            reviewdata={data}
            reviewtype="merchant_review"
          ></ReportComponent>
        </div>
        {responseData.length > 0 && (
          <div class="callout callout-info">
            <div className="responses mb-3">
              <p className="review_author">
                {t("ReviewMerchantPageWriteResponses")}
              </p>
              {responseData &&
                responseData.map((response, index) => (
                  <>
                    <div className="answer">
                      <div className="d-flex justify-content-between">
                        <p className="text-muted">
                          {t("ReviewMerchantPageBy")}{" "}
                          <a
                            href="#"
                            target="_blank"
                            className="review__author"
                          >
                            {response?.merchantUserName.replace("null", "")}
                          </a>
                        </p>
                        <p className="text-muted">
                          {" "}
                          {moment(response.createdAt).format("MMM DD. YYYY")}
                        </p>
                      </div>
                      <p>{response.content}</p>
                    </div>
                    <hr />
                  </>
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ServiceReview;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";
import ImageModal from "../ImageModal";
import ProofofPurchaseModal from "../ProofofPurchaseModal";

function ReviewModerationComponent({
  reviewData,
  onChangeChild,
  onApproveChild,
  onUpdate,
  indexValue,
}) {
  const axios = useAxios();
  const [user, setUser] = useState(null);
  const [display, setDisplay] = useState(true);
  const [disabaledOrderIdInput, setDisabaledOrderIdInput] = useState(
    reviewData.order_id ? true : false
  );
  const [orderId, setOrderId] = useState(reviewData.order_id);
  const [proofPurchase, setProofPurchase] = useState("");
  const initialImageVideoList = reviewData?.image_video
    ? reviewData.image_video.split(",")
    : [];

  const [imageVideoList, setImageVideoList] = useState(initialImageVideoList);
  const [NotificationMessage, setNotificationMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalShowImg, setModalShowImg] = useState([false, false, false]);
  const [entityName, setEntityName] = useState("");
  const [workerUser, setWorkerUser] = useState(null);
  const [statusProduct, setStatusProduct] = useState(null);

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
    if (reviewData.status !== "pending") setDisplay(false);
    let fetchReviews = async () => {
      const response = await axios.get(
        `/transactions/search/${reviewData.job_id}`
      );
      if (reviewData.type === "merchant_review") {
        const response = await axios.get(
          `/merchant_profiles/findmerchant/${reviewData.merchant_id}`
        );
        setEntityName(response?.data.website);
        const resp = await axios.get(
          `/merchant_profiles/findmerchant/${reviewData.merchant_id}`
        );
        setStatusProduct(resp.data.status);
      } else {
        setEntityName(reviewData.product_name);
        const response = await axios.get(
          `/products/getone/${reviewData.product_id}`
        );
        setStatusProduct(response.data.status);
      }

      const resp = await axios.get(`/users/${reviewData.user_id}`);
      setWorkerUser(resp.data);

      setProofPurchase(response.data.proofOfPurchase);
    };
    fetchReviews();
  }, []);

  const formatProduct_name = () => {
    var formatedName = reviewData.product_name;

    const accents = {
      a: /[àáâãäå]/g,
      e: /[èéêë]/g,
      i: /[ìíîï]/g,
      o: /[òóôõö]/g,
      u: /[ùúûü]/g,
      y: /[ýÿ]/g,
      n: /[ñ]/g,
      c: /[ç]/g,
    };

    for (let char in accents) {
      formatedName = formatedName.replace(accents[char], char);
    }

    formatedName = formatedName.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();

    return formatedName;
  };

  function handleReject() {
    let fetchReviews = async () => {
      if (reviewData.type === "merchant_review") {
        await axios.put(
          `/support/merchantreviews/merchantreview/${reviewData.id}`,
          { status: "deleted" }
        );
      } else {
        await axios.put(
          `/support/productreviews/productreview/${reviewData.id}`,
          { status: "deleted" }
        );
      }
      await axios.put(`/transactions/search/${reviewData.job_id}`, {
        status: "0",
      });

      // Add Notification

      await axios.post(`/notification/`, {
        userId: reviewData.user_id,
        notification_type: "emailing",
        status: "1",
        message: NotificationMessage,
      });
    };
    fetchReviews();
    setDisplay(false);
  }

  function handleApprove() {
    let fetchReviews = async () => {
      if (reviewData.type === "merchant_review") {
        await axios.put(
          `/support/merchantreviews/merchantreview/${reviewData.id}`,
          { status: "moderation", order_id: orderId }
        );
      } else {
        await axios.put(
          `/support/productreviews/productreview/${reviewData.id}`,
          { status: "moderation", order_id: orderId }
        );
      }
      await axios.put(
        `/support/productreviews/productreviewbyjobid/${reviewData.job_id}`,
        { order_id: orderId }
      );

      await axios.put(
        `/support/merchantreviews/merchantreviewbyjobid/${reviewData.job_id}`,
        { order_id: orderId }
      );

      const a = await axios.put(`/transactions/${reviewData.job_id}`, {
        status: "0",
      });

      if (a) {
        window.location.reload();
      }

      onApproveChild();
    };
    fetchReviews();
    setOrderId(orderId);
    setDisabaledOrderIdInput(true);
    setDisplay(false);
  }

  const handleDeleteImage = (indexToDelete) => {
    let fetchReviews = async () => {
      // Créez une copie de la liste actuelle
      const updatedImageVideoList = [...imageVideoList];

      // Supprimez l'élément à l'index spécifié
      updatedImageVideoList.splice(indexToDelete, 1);

      // Mettez à jour l'état avec la nouvelle liste
      setImageVideoList(updatedImageVideoList);
      const response = await axios.put(
        `/support/productreviews/productreview/${reviewData.id}`,
        { image_video: updatedImageVideoList.join(",") }
      );
    };
    fetchReviews();

    onChangeChild();
  };

  function handleModalClick() {
    setModalShow(true);
  }

  const handleModalImgClick = (indexToShow) => {
    switch (indexToShow) {
      case 0:
        setModalShowImg([true, modalShowImg[1], modalShowImg[2]]);
        break;
      case 1:
        setModalShowImg([modalShowImg[0], true, modalShowImg[2]]);
        break;
      case 2:
        setModalShowImg([modalShowImg[0], modalShowImg[1], true]);
        break;
      default:
    }
  };

  return (
    <>
      {
        <>
          {indexValue === 0 && (
            <div class="personal-data mb-4 bg-white shadow rounded-5 px-4 py-2 p-lg-4">
              <table className="table">
                <tbody>
                  <tr>
                    <th>User</th>
                    <td>{workerUser?.nickname || workerUser?.first_name}</td>
                  </tr>
                  <tr>
                    <th>
                      {reviewData.type === "merchant_review"
                        ? "Webshop"
                        : "Product name"}
                    </th>
                    <td className="text-warning">
                      {statusProduct === "0" ? (
                        <i class="flaticon-time-left me-3 text-warning"></i>
                      ) : (
                        <i class="flaticon-check me-3 text-success"></i>
                      )}
                      {reviewData.type === "merchant_review" && (
                        <Link
                          to={
                            "/" +
                            window.localStorage.getItem("langPage") +
                            "/admin-one-webshop/" +
                            reviewData.merchant_id
                          }
                        >
                          {entityName}{" "}
                        </Link>
                      )}
                      {reviewData.type === "product_review" && (
                        <Link
                          to={
                            "/" +
                            window.localStorage.getItem("langPage") +
                            "/admin-product/" +
                            reviewData.product_id
                          }
                        >
                          {entityName}{" "}
                        </Link>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Proof of purchase</th>
                    <td>
                      {disabaledOrderIdInput === true ? (
                        <>
                          <p className="mb-0 text-success">
                            {reviewData.order_id}
                          </p>
                        </>
                      ) : (
                        <button
                          class="btn btn-primary text-white me-3 preview btn-sm"
                          type="button"
                          onClick={handleModalClick}
                        >
                          Proof of purchase
                        </button>
                      )}
                      {/* <ProofofPurchaseModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        src={proofPurchase}
                        reviewData={reviewData}
                        onUpdate={() => {
                          onUpdate();
                          setOrderId(reviewData.order_id);
                        }}
                      ></ProofofPurchaseModal> */}
                    </td>
                  </tr>
                  {/* <tr>
                    <th>Status</th>
                    <td>{reviewData.status}</td>
                  </tr> */}
                  <tr>
                    <th>Date</th>
                    <td>
                      {moment(reviewData.createdAt).format("MMM DD, YYYY")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <div class="personal-data mb-4 bg-white shadow rounded-5 px-4 py-2 p-lg-4">
            <div class="title">
              <p class="lead me-auto">
                Validation data -{" "}
                <span
                  class={
                    reviewData.type === "merchant_review"
                      ? "badge bg-primary"
                      : "badge bg-success"
                  }
                >
                  {reviewData.type.replace("_", " ")}
                </span>
              </p>

              <button class="btn btn-danger ms-2" onClick={handleReject}>
                Reject
              </button>
              <button class="btn btn-success ms-2" onClick={handleApprove}>
                Approuve
              </button>
            </div>
            <div class="row">
              <div class="col-md-3 mb-3">
                <figure class="figure userpicture">
                  <figcaption class="figure-caption">
                    <button
                      class="btn btn-primary text-white me-3 preview"
                      type="button"
                      onClick={handleModalClick}
                    >
                      Proof of purchase
                    </button>
                  </figcaption>
                </figure>
                <ProofofPurchaseModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  src={proofPurchase}
                  reviewData={reviewData}
                  onUpdate={() => {
                    onUpdate();
                    setOrderId(reviewData.order_id);
                  }}
                ></ProofofPurchaseModal>
                <p>Rating: {reviewData.rating}/5</p>
                <p>
                  Date: {moment(reviewData.createdAt).format("MMM DD, YYYY")}
                </p>
              </div>
              <div class="col-md mb-3">
                {reviewData.type === "product_review" && (
                  <div>
                    <p className="text-primary mb-0">Product name</p>
                    <p className="lead">
                      <Link
                        to={
                          "/" +
                          window.localStorage.getItem("langPage") +
                          "/admin-product/" +
                          reviewData.product_id
                        }
                      >
                        {statusProduct === "0" ? (
                          <i class="flaticon-time-left me-3 text-warning"></i>
                        ) : (
                          <i class="flaticon-check me-3 text-success"></i>
                        )}
                        <strong>{entityName}</strong>
                      </Link>
                    </p>
                  </div>
                )}
                <div class="username mb-3">
                  <label class="text-primary" for="username">
                    Title review
                  </label>
                  <input
                    id="username"
                    class="form-control"
                    type="text"
                    value={reviewData.title}
                    disabled={true}
                  />
                </div>
                <div class="order mb-3">
                  <label class="text-primary" for="id_order">
                    ID Order
                  </label>
                  <input
                    id="id_order"
                    class="form-control"
                    type="text"
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                    disabled={disabaledOrderIdInput}
                    placeholder="Insert the order number for this purchase"
                  />
                </div>
                <div class="content mb-3">
                  <label class="text-primary" for="Content">
                    Content review
                  </label>
                  <textarea
                    id="Content"
                    class="form-control"
                    type="text"
                    value={reviewData.content}
                    disabled={true}
                  >
                    {reviewData.content}
                  </textarea>
                </div>
                <div class="reject mb-3">
                  <label class="text-primary" for="Content">
                    Reject message explication
                  </label>
                  <textarea
                    id="Notification"
                    class="form-control"
                    type="text"
                    value={NotificationMessage}
                    onChange={(e) => setNotificationMessage(e.target.value)}
                  ></textarea>
                </div>
                <div class="d-flex justify-content-between">
                  {imageVideoList &&
                    imageVideoList.map((image_video, index) => (
                      <>
                        <figure class="figure product_user">
                          <button
                            type="button"
                            class="btn-close"
                            aria-label="Close"
                            onClick={() => handleDeleteImage(index)}
                          ></button>
                          <img
                            class=""
                            src={image_video}
                            alt="user-avatar"
                            width={"150"}
                            onClick={() => handleModalImgClick(index)}
                          />
                        </figure>
                        <ImageModal
                          show={modalShowImg[index]}
                          onHide={() => setModalShowImg([false, false, false])}
                          src={image_video}
                        ></ImageModal>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default ReviewModerationComponent;

import CryptoJS from "crypto-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAt/3R6qvoXNLbaLurkSHr
zEV+n850tYp0RNUNby7tBDs1euwIYjUIvd2RmGA6IDtCu/WNVxa5i0XXEepI3O6S
P8im++gjkDfgBiicCyrAgjl9ZN7JAZy8dSYaw0h/YzfU3aN/l04q/GSMFNaHSzFt
HjfrOfAsEgAqNl3YgdMR3MqPfFZsm7N0BEZhfwW/y7HO6+aX45H0KBs4fM8x08NL
Y0dJGsT3lN72FG/KBh2t+9DbidcIB5R5I89LZeLudsb/zMNy94uR4jktkfP2zw0g
bTkwDe7E/Y5ETN7N09KMAroxCBCifKSSjhTHfuUTM15+cYpassxiMx3pZB/eSD5a
qQIDAQAB
-----END PUBLIC KEY-----`; /* Clé publique récupérée du serveur */

function Nav({ isActive, handleClick }) {
  const navigate = useNavigate();
  const [userid, setUserid] = useState(0);
  const [levelUser, setLevelUser] = useState(0);
  const [error, setError] = useState("");
  const [userData, setUserData] = useState({}); //useSelector((state) => state.auth.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const axios = useAxios();
  const { t } = useTranslation();

  // useEffect(() => {
  //   togglePassword();
  // }, []);

  const handleLogout = () => {
    // dispatch(logout());

    axios.get("/logout").then((res) => {
      window.location.href = "/";
    });

    //window.location.reload(true);
    window.localStorage.removeItem("userdata");
    window.localStorage.removeItem("me");
  };

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    let fetchReviews = async () => {
      const response = await axios.get("/");
      if (response?.data) {
        setUserid(response.data["id"]);
        setLevelUser(response.data["level_account"]);
        setUserData(response.data);
        // if (window.localStorage.getItem("userdata")) {
        //   const retrievedObject = JSON.parse(
        //     window.localStorage.getItem("userdata")
        //   );
        //   setUserid(retrievedObject["id"]);
        //   setLevelUser(retrievedObject["level_account"]);
        //   setUserData(retrievedObject);
        // }
        const jsonString = JSON.stringify(response.data);
        console.log("jsonString", jsonString);

        window.localStorage.setItem("userdata", jsonString);
      }
    };
    fetchReviews();
  }, []);

  //comportement
  function handleChange(e) {
    const { name, value } = e.target;
    setUser((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = JSON.stringify(user);
    const encryptedPayload = CryptoJS.AES.encrypt(
      payload,
      publicKey
    ).toString();

    axios
      .post("/login", user, true)
      .then((res) => {
        if (res.data["success"] == true || res.data.token) {
          //.log(res.data);
          console.log("res.data.token", res.data.token);

          window.location.href = "/account";
        } else {
          //.log("Error to connect");
        }
      })
      .catch((err) => {
        //.log(err);
        toast.error("Invalid password or username", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  // Comportements
  function handleClickLogout() {
    // Supprime les cookies en les définissant avec une date d'expiration passée
    document.cookie =
      "connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.veritatrust.com;";
    document.cookie =
      "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.veritatrust.com;";
    window.localStorage.removeItem("userdata");
    window.localStorage.removeItem("me");

    window.location.href = "/"; // Redirige vers la page d'accueil après la déconnexion
  }

  return (
    <>
      {userid > 0 ? (
        <div
          className={
            isActive
              ? "offcanvas offcanvas-end text-bg-primary show"
              : "offcanvas offcanvas-end text-bg-primary"
          }
          data-bs-scroll="true"
          tabIndex="-1"
          id="account"
          aria-labelledby="offcanvasWithBothOptionsLabel"
          aria-modal="true"
          role="dialog"
        >
          {" "}
          <div className="offcanvas-body">
            <div className="offcanvas-header">
              <h5
                className="offcanvas-title"
                id="offcanvasWithBothOptionsLabel"
              >
                {t("AccountH1")}
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={handleClick}
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul class="list-unstyled list-profile">
                <li>
                  <a
                    className={
                      location.pathname === "/account/" ? "active" : ""
                    }
                    href="/account/"
                  >
                    {t("AccountDashboard")}
                  </a>
                </li>
                <li>
                  <a
                    className={
                      location.pathname === "/account/settings/" ? "active" : ""
                    }
                    href="/account/settings/"
                  >
                    {t("AccountProfile")}
                  </a>
                </li>
                <li>
                  <a
                    className={
                      location.pathname === "/account/rewards/" ? "active" : ""
                    }
                    href="/account/rewards/"
                  >
                    {t("AccountRewards")}
                  </a>
                </li>
                <li>
                  <a
                    className={
                      location.pathname === "/account/reviews/" ? "active" : ""
                    }
                    href="/account/reviews/"
                  >
                    {t("AccountReviews")}
                  </a>
                </li>
                <li>
                  <a
                    className={
                      location.pathname === "/account/community/"
                        ? "active"
                        : ""
                    }
                    href="/account/community/"
                  >
                    {t("AccountCommunity")}
                  </a>
                </li>
                <li>
                  <a
                    className={
                      location.pathname === "/account/badges/" ? "active" : ""
                    }
                    href="/account/badges/"
                  >
                    {t("AccountBadges")}
                  </a>
                </li>
                <li>
                  <a
                    className={
                      location.pathname === "/account/support/" ? "active" : ""
                    }
                    href="/account/support/"
                  >
                    {t("AccountSupport")}
                  </a>
                </li>
                <li>
                  <a
                    className={
                      location.pathname === "/account/notifications/"
                        ? "active"
                        : ""
                    }
                    href="/account/notifications/"
                  >
                    {t("AccountNotifications")}
                  </a>
                </li>
                <li>
                  <a
                    className={
                      location.pathname === "/account/faq/" ? "active" : ""
                    }
                    href="/account/faq/"
                  >
                    {t("AccountFAQ")}
                  </a>
                </li>
                <li>
                  <a
                    className={
                      location.pathname === "/account/vouchers/" ? "active" : ""
                    }
                    href="/account/vouchers"
                  >
                    {t("AccountVouchers")}
                  </a>
                </li>
              </ul>
              <button
                class="btn btn-success text-white"
                onClick={handleClickLogout}
              >
                {t("HeaderNavbarLogout")}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={
            isActive
              ? "offcanvas offcanvas-end text-bg-primary show"
              : "offcanvas offcanvas-end text-bg-primary"
          }
          data-bs-scroll="true"
          tabIndex="-1"
          id="account"
          aria-labelledby="offcanvasWithBothOptionsLabel"
          aria-modal="true"
          role="dialog"
        >
          {" "}
          <div className="offcanvas-body">
            <div className="offcanvas-header">
              <h5
                className="offcanvas-title"
                id="offcanvasWithBothOptionsLabel"
              >
                {t("HeaderNavbarSocial")}{" "}
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={handleClick}
              ></button>
            </div>

            <div className="offcanvas-body">
              <ul className="social-Connect">
                <li>
                  <a
                    className="text-white me-2 pe-2"
                    href="https://api.veritatrust.com/v1/auth/facebook"
                  >
                    <i className="flaticon-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="text-white mx-2 px-2"
                    href="https://api.veritatrust.com/v1/auth/google"
                  >
                    <i className="flaticon-google"></i>
                  </a>
                </li>
                <li>
                  <button title="Connect with MetaMask">
                    <i
                      className="flaticon-logo-metamask-fil"
                      id="metaloginbtn"
                    ></i>
                  </button>
                </li>
                <li className="d-none">
                  <button title="Connect with Apple">
                    <i className="flaticon-apple"></i>
                  </button>
                </li>
              </ul>
              <div className="orbymail text-center">
                <div className="separator">{t("HeaderNavbarOr")}</div>
              </div>
              <p>{t("HeaderNavbarLoginPass")}</p>
              <form id="login" onSubmit={handleSubmit}>
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control"
                    value={user.email}
                    onChange={handleChange}
                    name="email"
                    id="usermail"
                    aria-describedby="emailHelp"
                    title="User Email"
                    placeholder={t("HeaderNavbarEmail")}
                  />
                  <span className="input-group-text bg-success text-white border-success">
                    <i className="flaticon-user"></i>
                  </span>
                </div>
                <div id="emailHelp" className="form-text text-white mb-3">
                  {t("HeaderNavbarPrivacy")}
                </div>
                <div className="input-group mb-3 ">
                  <input
                    type="password"
                    className="form-control"
                    value={user.password}
                    onChange={handleChange}
                    id="userpassword"
                    name="password"
                    title="Password"
                    placeholder={t("HeaderNavbarPassword")}
                  />
                  <span className="input-group-text bg-success toggle-password text-white border-success">
                    <i className="flaticon-hide"></i>
                  </span>
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="rememberme"
                    checked={user.stayConnected && true}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="rememberme">
                    {t("HeaderNavbarConnected")}
                  </label>
                </div>
                <button
                  type="submit"
                  className="btn btn-success"
                  id="userbutton"
                >
                  {t("HeaderNavbarLogin")}
                </button>
              </form>

              <div className="d-none" id="error-message-2">
                <div className="me-3">
                  <i className="flaticon-information"></i>
                </div>
                <div>Error message</div>
              </div>
              <hr />

              <a className="btn btn-light" href="/resetpassword">
                {t("HeaderNavbarPass")}
              </a>

              <p className="mt-3 mb-1">{t("HeaderNavbarNotRegistered")}</p>

              <a href={"/" + t("lang") + "/signup"} className="btn btn-light">
                {t("HeaderNavbarCreate")}{" "}
              </a>
            </div>
          </div>
        </div>
      )}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default Nav;

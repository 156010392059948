import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function CreateWebshopInput({
  handleSelectWebshop,
  startReviewButtonDisabled,
  resetInput,
}) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false);
  const [checkedurl, setCheckedurl] = useState(false);

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);
  useEffect(() => {}, [resetInput]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);
    setCheckedurl(isURL(inputValue));

    // Send a request to the server to search for categories matching the input value
    if (
      inputValue.length >= 2 &&
      inputValue !== "www." &&
      inputValue !== "www" &&
      inputValue !== "ww"
    ) {
      axios
        .get(`/merchant_profiles/search-results/${inputValue}`)
        .then((response) => {
          setSearchResults(response.data);
          setShow(true);
        })
        .catch((error) => {
          setSearchResults([]);

          console.error(error);
        });
    } else {
      setSearchResults([]);
      setShow(false);
    }
  };

  const handleSelectWebshopInput = (suggestion) => {
    setSearchResults([]);
    setSearchTerm(suggestion.website);
    setShow(false);
    handleSelectWebshop(suggestion);
  };

  const axios = useAxios();

  function handleAddNewWebshop() {
    axios
      .post(`/merchant_profiles/`, {
        name: searchTerm,
        website: searchTerm,
      })
      .then((response) => {
        if (searchTerm.length >= 1) {
          axios
            .get(`/merchant_profiles/search-results/${searchTerm}`)
            .then((response) => {
              setSearchResults(response.data);
              setShow(true);
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          setSearchResults([]);
          setShow(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function isURL(val) {
    var regex = /^(www\.)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,6}$/;

    return regex.test(val);
  }
  return (
    <>
      <div className="row mb-md-3">
        <label htmlFor="productlink" className="col-md-3 col-form-label">
          {t("AddReviewForBetaTesterWebshop")}
          <sup>*</sup>
        </label>
        <div className="col-md-9 dropdown">
          <input
            type="text"
            className="form-control"
            id="productlink"
            required
            name="productlink"
            onChange={handleInputChange}
            value={searchTerm}
            autoComplete="off"
            placeholder="Ex: www.amazon.fr"
            disabled={startReviewButtonDisabled | (resetInput > 0)}
            onBlur={() => {
              setTimeout(() => setShow(false), 200);
            }}
            onFocus={() => {
              setShow(true);
            }}
          />

          {searchTerm.length >= 2 && (
            <ul className={show ? "dropdown-menu show" : "dropdown-menu"}>
              {searchResults.map((suggestion, index) => (
                <li key={index}>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => handleSelectWebshopInput(suggestion)}
                  >
                    {suggestion.website}
                  </button>
                </li>
              ))}
              {searchResults.length === 0 && searchTerm.length >= 2 && (
                <>
                  <li>
                    <p className="dropdown-header">
                      {t("AddReviewForBetaTesterNotFoundWebshopMsg")}
                    </p>
                  </li>
                  {!checkedurl && (
                    <li>
                      <p className="dropdown-header callout callout-error mt-1">
                        {t("AddReviewForBetaTesterNoUrlFormatMsg")}
                      </p>
                    </li>
                  )}
                  <li>
                    <button
                      className="dropdown-item active"
                      type="button"
                      onClick={handleAddNewWebshop}
                      disabled={!checkedurl}
                    >
                      {t("AddReviewForBetaTesterCreateWebshopBtn")}
                    </button>
                  </li>
                </>
              )}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateWebshopInput;
